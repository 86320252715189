import { Fragment } from "react"
import {Navbar} from '../components/Header'
import {Footer} from '../components/footer'



export const NotFound = () => {
    return (
        <Fragment>
        <Navbar/>
        <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <img src="/logo.png" style={{width: '8vw'}} /> 
            <div className="max-w-md text-center">
                <h1 className="mb-8 font-extrabold text-9xl md:text-9xl sm:text-9xl dark:text-gray-600">
                <span className=" mt-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-emerald-400 to-green-500 md:inline-block"> <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 via-cyon-400 to-purple-300"> 404</span> </span>
                </h1>
                <p className="text-2xl font-semibold md:text-1xl">Sorry, we couldn't find this page.</p>
                <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p>
                <a  href="/" className="px-8 py-3 font-semibold bg-gray-900 text-gray-100 text-1xl md:text-lg rounded dark:bg-violet-400 dark:text-gray-900">Back to homepage</a>
            </div>
        </div>
        </section>
        <Footer/>
        </Fragment>
    )
}