import {Fragment} from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import {Provider} from 'react-redux'
import STORE from '../redux/Store'
import {MainPage} from '../pages/Main'
import {Blog} from '../pages/Blog'
import {NotFound} from '../pages/404'
import {LoginPage} from '../pages/Login'

const RoutedRoutes = () => {
    return (
        <Fragment>
        <Provider store={STORE}>
        <BrowserRouter>
        <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/article/:id" element={<Blog />} />
        <Route path='/login' element={<LoginPage/>} />
        <Route path='*' element={<NotFound/>} />
        </Routes>
        </BrowserRouter>,
        </Provider>
        </Fragment>
    )
}


export {RoutedRoutes}