import { Fragment } from "react"
import {Navbar} from '../components/Header'
import {Footer} from '../components/footer'
import {Deck} from '../components/Deck'
import {Main} from '../components/Main'
import {HorizontalSlider} from '../components/HorizontalSlider'
import {AdBanner} from '../components/AdBanner'


export const MainPage = () => {
    return (
        <Fragment>
        <Navbar/>
        <AdBanner/>
        <HorizontalSlider/>
        <Main/>
        <Deck/>
        <Footer/>
        </Fragment>
    )
}