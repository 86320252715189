import {Fragment} from 'react';
import './styles/index.css'
import './styles/systemConfig.css'
import {RoutedRoutes} from './router/routes'


function App() {
  return (
   <Fragment>
    <RoutedRoutes/>
   </Fragment>
  );
}

export default App;
