export const Main = () => {
    return (
        <><div>
            <section>
                <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <p className="text-7xl font-medium text-green-600">
                            Best Prices for Gadgets
                        </p>
                    </div>
                </div>
            </section>
        </div><section>
                <div className="mx-auto  px-52 py-8">
                    <div className="relative mx-auto max-w-3xl text-center">
                        <span className="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-black/10" />
                        <h2 className="relative inline-block bg-white  text-center text-2xl font-bold">
                            Recently Viewed
                        </h2>
                    </div>
                    <div className="mt-8 grid grid-cols-2 gap-x-4 gap-y-8 lg:grid-cols-5">
                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://images.unsplash.com/photo-1603356033288-acfcb54801e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">Robot Toy</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://images.unsplash.com/photo-1603356033288-acfcb54801e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">Robot Toy</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://images.unsplash.com/photo-1603356033288-acfcb54801e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">Robot Toy</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://images.unsplash.com/photo-1603356033288-acfcb54801e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">Robot Toy</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>

                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://images.unsplash.com/photo-1603356033288-acfcb54801e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">Robot Toy</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://images.unsplash.com/photo-1603356033288-acfcb54801e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">Robot Toy</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://images.unsplash.com/photo-1603356033288-acfcb54801e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">Robot Toy</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://images.unsplash.com/photo-1603356033288-acfcb54801e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">Robot Toy</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://m.media-amazon.com/images/I/61iD0U2YwqL._AC_SX679_.jpg" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">Robot Toy</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <a href="#" className="relative block border border-gray-100">
                            <button type="button" className="absolute right-4 top-4 rounded-full bg-black p-2 text-white">
                                <span className="sr-only">Wishlist</span>
                                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <img alt="Toy" src="https://m.media-amazon.com/images/I/81iKGq2f77L._AC_SX679_.jpg" className="h-56 w-full object-contain" />
                            <div className="p-6">
                                <p className="text-sm font-medium text-gray-600">$14.99</p>
                                <h3 className="mt-1 text-lg font-bold">ZOTAC Gaming GeForce RTX™ 3060 Ti Twin Edge OC LHR 8GB</h3>
                                <button type="button" className="mt-4 flex w-full items-center justify-center rounded-sm bg-yellow-500 px-8 py-4">
                                    <span className="text-sm font-medium"> Buy Now </span>
                                    <svg className="ml-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                    </svg>
                                </button>
                            </div>
                        </a>
                    </div>
                    <div className="mt-4 text-center">
                        <button type="button" className="text-xs text-gray-500 underline">
                            Clear Recently Viewed
                        </button>
                    </div>
                </div>
            </section></>
       
    
     

    )
}