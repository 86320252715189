import { Fragment } from "react"
import {Navbar} from '../components/Header'
import {Footer} from '../components/footer'
import {RelatedBlogs2} from '../partials/RelatedBlogs2'


export const Blog = () => {
    return (
        <Fragment>
        <Navbar/>
        <div className="max-w-6xl px-6 pt-6  mx-auto space-y-12">
  <article className="space-y-8 dark:bg-gray-800 dark:text-gray-50">
    <div className="space-y-6">
      <h1 className="text-3xl font-bold md:tracking-tight md:text-7xl">Best Ideas to renovate the House and do domestic cleaning</h1>
      <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center dark:text-gray-400">
        <div className="flex items-center md:space-x-2">
          <img src="https://source.unsplash.com/75x75/?portrait" alt="" className="w-4 h-4 border rounded-full dark:bg-gray-500 dark:border-gray-700" />
          <p className="text-sm"> July 19th, 2021 - 4 min read</p>
        </div>
      </div>
    </div>
    <div className="dark:text-gray-100">
      <p>Modern House and Dorms</p>
    </div>
  </article>
  <div>
</div>
</div>
<div className="max-w-6xl mx-auto px-6">
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum perferendis tempore nulla consectetur odit dolor! Impedit libero fuga eaque nam modi, ipsum molestiae aspernatur dolorem temporibus quidem corrupti nesciunt sint.</p>
</div>

<div className="max-w-6xl mx-auto">   
  <section className="p-6 dark:bg-gray-800 dark:text-gray-100">
  <div className="container grid gap-6 mx-auto text-center lg:grid-cols-2 xl:grid-cols-5">
  <img src="https://images.pexels.com/photos/1370704/pexels-photo-1370704.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="" className="object-cover w-full rounded-md xl:col-span-3 dark:bg-gray-500" />
    <div className="w-full px-6 py-16 rounded-md sm:px-12 md:px-16 xl:col-span-2 dark:bg-gray-900">
      <span className="block mb-2 dark:text-violet-400">Mamba design system</span>
      <h1 className="text-5xl font-extrabold dark:text-gray-50">Build it with Mamba</h1>
      <p className="my-8">
        <span className="font-medium dark:text-gray-50">Modular and versatile.</span>Fugit vero facilis dolor sit neque cupiditate minus esse accusamus cumque at.
      </p>
     <p>
     Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
numquam blanditiis.
     </p>
    </div>
  
  </div>
</section>
</div>   
        <RelatedBlogs2/>
        <Footer/>
        </Fragment>
    )
}